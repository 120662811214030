<template>
  <v-container fluid pa-0 >
    <about-section
      title="ZEP die beste Brauerei allerzeiten"
      text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
      :imgSrc="require('../assets/logo.png')"
      :imgRight="true"
    />
    <about-section
      title="Entstehungsgeschichte"
      text="Es war einmal vor langer Zeit in einer weit, weit entfernten Galaxis... <br/> <br/> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
      :imgSrc="require('../assets/logo.png')"
      :imgRight="false"
    />
    <about-section
      title="ZEP-Süd"
      text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
      :imgSrc="require('../assets/logo.png')"
      :imgRight="true"
    />
    <v-container fluid px-16 py-10 class="text-h2 font-weight-bold" style="background: #fafafa">
      Unser Team
      <v-row justify="center" class="px-4 py-8">
        <v-col v-for="member in members" :key="member.name" lg="4" xl="4" md="4" sm="12" xs="12">
          <ProfileItem :member="member"/>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import AboutSection from '../components/AboutSection.vue'
import ProfileItem from '../components/ProfileItem.vue'
export default {
  components: { AboutSection, ProfileItem },
  data() {
    return {
      members: [
        {
          name: "Marius Keller",
          position: "CEO & Founder",
          quote: "Ottertastisch!",
          imgSrc: ""
        },
        {
          name: "Justus Köhler",
          position: "CDO & Founder",
          quote: "Ottertastisch!",
          imgSrc: ""
        },
        {
          name: "Otter",
          position: "Maskottchen",
          quote: "Ottertastisch!",
          imgSrc: ""
        },
        {
          name: "Ben Keller",
          position: "CTO & Founder ZEP-Süd",
          quote: "Ottertastisch!",
          imgSrc: ""
        },
        {
          name: "Jannis Keller",
          position: "CMO & Founder ZEP-Süd",
          quote: "Ottertastisch!",
          imgSrc: ""
        },
        {
          name: "Ruben Röhner",
          position: "CIO & Founder ZEP-Süd",
          quote: "Ottertastisch!",
          imgSrc: ""
        }
      ]
    }
  }

}
</script>

<style>

</style>
