<template>
  <v-card class="ma-8 pa-6 grey darken-4 white--text rounded-xl">
      <v-img v-if="member.imgSrc"
      height="200px"
      contain
      :src="member.imgSrc"
      />
      <v-icon v-else class="pa-4" color="white" size="168px">fa-user-circle</v-icon>
      <v-card-title class=" white--text text-h5 text-center font-weight-medium justify-center"> 
          {{ member.name }}
      </v-card-title>
      <v-card-subtitle class=" white--text text-overline mb-4">
          {{ member.position }}
      </v-card-subtitle>
      <v-icon color="yellow">fa-quote-right</v-icon>
      <v-card-text class=" white--text text-body1 text-center">
          {{ member.quote }}
      </v-card-text>
  </v-card>
</template>

<script>
export default {
    props: {
        member: Object,
    }
}
</script>

<style>

</style>